.customSquare {
  width: calc(50% - 0.5rem);
  height: 0;
  padding-bottom: calc(50% - 0.5rem);
}

.customCounter {
  width: calc(25% - 0.5rem);
  height: 0;
  padding-bottom: calc(25% - 0.5rem);
}
