.centerPositionSquare {
  top: -10px;
  left: calc(50% - 10px);
}
.centerPositionSlash {
  top: 10px;
  left: calc(47% - 10px);
}
.centerPositionButton {
  top: -45px;
  left: calc(40% - 20px);
}
.bottomPositionImg {
  top: -800px;
}
.bottomPositionCircles {
  top: -15px;
  right: -4px;
}
.svgLeft {
  left: -11px;
}
.leftPositionModal {
  top: -10px;
  right: calc(60%);
}
.arrowPositionModal {
  top: -10px;
  right: calc(20%);
}
.topPositionModal {
  bottom: -10px;
  right: calc(20%);
}
.modalTransition {
  transition-duration: 10s;
  transition-timing-function: ease-in;
}
 .textPositionning {
  bottom: 45%;
  /* right: 45%; */
  top: 45%;
}
.smallTextPositionning {
  bottom: 25%;
  /* right: 25%; */
} 



