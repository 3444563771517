@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #B4C7C9;
  font-family: 'interregular', 'neue_machinaultrabold', 'neue_machinaregular', 'neue_machinalight',
    'quenchregular', serif;
}
@font-face {
  font-family: 'interregular';
  src: url('fonts/inter-webfont.woff2') format('woff2'),
    url('fonts/inter-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neue_machinaultrabold';
  src: url('fonts/neuemachina-ultrabold-webfont.woff2') format('woff2'),
    url('fonts/neuemachina-ultrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neue_machinaregular';
  src: url('fonts/neuemachina-regular-webfont.woff2') format('woff2'),
    url('fonts/neuemachina-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'neue_machinalight';
  src: url('fonts/neuemachina-light-webfont.woff2') format('woff2'),
    url('fonts/neuemachina-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'quenchregular';
  src: url('fonts/quench-webfont.woff2') format('woff2'),
    url('fonts/quench-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'cutive';
  src: url('fonts/cutive-regular-webfont.woff2') format('woff2'),
    url('fonts/cutive-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'sharpGrotesk';
  src: url('fonts/sharpgroteskbold23-regular-webfont.woff2') format('woff2'),
    url('fonts/sharpgroteskbold23-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.setColorsPadding {
  margin-left: -3%;
}
.setWritingPadding {
  margin-right: 2%;
}